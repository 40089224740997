import { type LoaderFunctionArgs } from '@remix-run/node';
import { Outlet } from '@remix-run/react';

import { redirectMiddleware } from '~/utils/auth.server';


export const loader = async (args: LoaderFunctionArgs) => {
  await redirectMiddleware('auth', args);
  return null;
};

export default function AuthLayout() {
  return (
    <div className="flex items-center justify-center w-screen h-screen bg-beige">
      <div className="mx-auto">
        <Outlet />
      </div>
    </div>
  );
}
